
.word {
    display: inline-block;
    widows: 14px;
    height: 14px;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
}
.button {
    //width: 77px;
    height: 40px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
}
.create-bag-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    .student-create-bag {
        .path {
            display: flex;
            margin-left: 6px;
            margin-top: 21px;
            color: #5C5C6F;
            .item {
                display: flex;
                justify-content: center;
                align-items: center;
                .text {
                    transition: all 0.3s;
                    cursor: pointer;
                }
                .text:hover {
                    color: #2821fc;
                }
            }
            .current {
                color: #ABABBB;
            }
        }
        .create-bag-title {
            //width: 64px;
            height: 16px;
            margin: 21px 0 14px 5px;
            font-size: 16px;
            font-weight: 500;
            color: #fff;
        }
        .bag-input {
            color: #fff;
            .tips {
                color: red;
                margin-left: 6px;
            }
            .bagTitle {
                //width: 72px;
                height: 14px;
                font-size: 14px;
                font-weight: 400;
                margin-left: 6px;
            }
            .el-input {
                width: 380px;
                height: 40px;
                // margin-top: 10px;
                margin: 10px 0 0 7px;
                border-radius: 4px;
            }
            .word1 {
                margin-left: 8px;
                @extend .word
            }
            .word2 {
                margin-left: 11px;
                @extend .word
            }
            .el-input-countdown {
                width: 172px;
                height: 40px;
            }
            .form-button {
                position: fixed;
                bottom: 1.85%;
                left: 68%;
                //width: 167px;
                height: 40px;
            }
        }
        .new-popper__arrow {
            width: 0;
        }
    }
}

::v-deep .el-form {
    .el-form-item {
        margin-bottom: 0;
    }
    .el-input__inner {
        color: #FFFFFF;
        background-color: #0C0E3F !important;
        border: 1px solid transparent;
        &::placeholder {
            color: #FFFFFF;
            opacity: 0.2;
            font-size: 14px;
            font-weight: 400;
        }
    }
    .el-input__inner:focus {
        border: 1px solid #4B45FF;
    }
    .el-form-item__error {
        margin-left: 7px;
    }
    .el-select {
        width: 380px;
        margin-top: 10px;
    }
    .el-icon-arrow-up:before {
        content: "\e790";
        color: #fff;
        opacity: 0.5;
        width: 12px;
        height: 7px;
    }
    .el-select-dropdown {
        height: auto;
        border: 1px solid transparent;
        background-color: #0C0E3F;
    }
    .el-scrollbar {
       height: auto;
    }
    .el-scrollbar__wrap {
        margin-bottom: 0 !important;
    }
    .el-select-dropdown__item {
        height: 36px;
        margin-top: 9px;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
    }
    .el-select-dropdown__item:hover {
        background-color: #262963;
    }
    .el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
        background-color: #262963;
    }
    .el-select-dropdown__item.selected {
        background-color: #262963;
    }
    .el-button {
        color: #fff;
        border: 1px solid #262963;
        background: #0C0E3F;
    }
    .el-button--primary {
        background: #4B45FF;
    }
    .el-button+.el-button {
        margin-left: 12px;
    }
}
